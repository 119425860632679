
import { defineComponent, reactive, ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import useVuelidate from '@vuelidate/core'
import { getTicketsByEmail } from '@/expressway-api/travelAccountJourneys.api'
import { required, email, helpers, minLength, numeric } from '@vuelidate/validators'
import useSnackbar from '@/composables/useSnackbar'
import { useRouter } from 'vue-router'
import Spinner from '@/components/Spinner.vue'
import Input from '@/components/Input.vue'
import GenericError from '@/components/GenericError.vue'

export default defineComponent({
  components: {
    PageHeader,
    Spinner,
    'v-input': Input,
    GenericError
  },
  setup () {
    const { setSnackbar } = useSnackbar()
    const router = useRouter()
    const loading = ref(false)

    const state = reactive({
      email: '',
      ticketId: ''
    })

    const rules = {
      email: {
        required,
        email: helpers.withMessage('Please enter a valid Email Address', email)
      },
      ticketId: {
        required: helpers.withMessage('Please enter a valid ticket ID', required),
        minLength: helpers.withMessage('Ticket number must be minimum 12 numbers', minLength(12)),
        numeric
      }
    }

    const loadBookingInfo = async () => {
      loading.value = true
      const ShoppingBasketId = await getTicketsByEmail(state.email, state.ticketId)
      if (ShoppingBasketId !== false) {
        router.push({ name: 'BookingInfo', params: { id: ShoppingBasketId } })
      } else {
        setSnackbar('Booking Info not found')
      }
      loading.value = false
    }

    return {
      v$: useVuelidate(rules, state),
      loadBookingInfo,
      loading
    }
  }
})
